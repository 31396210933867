// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-1-the-power-of-relationships-a-introduction-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/1-the-power-of-relationships/a-introduction.jsx" /* webpackChunkName: "component---src-pages-1-the-power-of-relationships-a-introduction-jsx" */),
  "component---src-pages-1-the-power-of-relationships-b-informal-time-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/1-the-power-of-relationships/b-informal-time.jsx" /* webpackChunkName: "component---src-pages-1-the-power-of-relationships-b-informal-time-jsx" */),
  "component---src-pages-1-the-power-of-relationships-c-coach-to-athlete-relationships-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/1-the-power-of-relationships/c-coach-to-athlete-relationships.jsx" /* webpackChunkName: "component---src-pages-1-the-power-of-relationships-c-coach-to-athlete-relationships-jsx" */),
  "component---src-pages-1-the-power-of-relationships-d-athlete-to-athlete-relationships-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/1-the-power-of-relationships/d-athlete-to-athlete-relationships.jsx" /* webpackChunkName: "component---src-pages-1-the-power-of-relationships-d-athlete-to-athlete-relationships-jsx" */),
  "component---src-pages-1-the-power-of-relationships-quiz-1-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/1-the-power-of-relationships/quiz-1.jsx" /* webpackChunkName: "component---src-pages-1-the-power-of-relationships-quiz-1-jsx" */),
  "component---src-pages-1-the-power-of-relationships-quiz-2-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/1-the-power-of-relationships/quiz-2.jsx" /* webpackChunkName: "component---src-pages-1-the-power-of-relationships-quiz-2-jsx" */),
  "component---src-pages-1-the-power-of-relationships-quiz-3-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/1-the-power-of-relationships/quiz-3.jsx" /* webpackChunkName: "component---src-pages-1-the-power-of-relationships-quiz-3-jsx" */),
  "component---src-pages-1-the-power-of-relationships-quiz-4-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/1-the-power-of-relationships/quiz-4.jsx" /* webpackChunkName: "component---src-pages-1-the-power-of-relationships-quiz-4-jsx" */),
  "component---src-pages-1-the-power-of-relationships-quiz-5-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/1-the-power-of-relationships/quiz-5.jsx" /* webpackChunkName: "component---src-pages-1-the-power-of-relationships-quiz-5-jsx" */),
  "component---src-pages-1-the-power-of-relationships-quiz-6-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/1-the-power-of-relationships/quiz-6.jsx" /* webpackChunkName: "component---src-pages-1-the-power-of-relationships-quiz-6-jsx" */),
  "component---src-pages-2-safe-supportive-culture-a-introduction-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/2-safe-supportive-culture/a-introduction.jsx" /* webpackChunkName: "component---src-pages-2-safe-supportive-culture-a-introduction-jsx" */),
  "component---src-pages-2-safe-supportive-culture-b-it-starts-with-safety-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/2-safe-supportive-culture/b-it-starts-with-safety.jsx" /* webpackChunkName: "component---src-pages-2-safe-supportive-culture-b-it-starts-with-safety-jsx" */),
  "component---src-pages-2-safe-supportive-culture-c-setting-non-negotiables-and-understanding-context-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/2-safe-supportive-culture/c-setting-non-negotiables-and-understanding-context.jsx" /* webpackChunkName: "component---src-pages-2-safe-supportive-culture-c-setting-non-negotiables-and-understanding-context-jsx" */),
  "component---src-pages-2-safe-supportive-culture-d-cultivating-friendship-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/2-safe-supportive-culture/d-cultivating-friendship.jsx" /* webpackChunkName: "component---src-pages-2-safe-supportive-culture-d-cultivating-friendship-jsx" */),
  "component---src-pages-2-safe-supportive-culture-e-traditions-with-team-time-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/2-safe-supportive-culture/e-traditions-with-team-time.jsx" /* webpackChunkName: "component---src-pages-2-safe-supportive-culture-e-traditions-with-team-time-jsx" */),
  "component---src-pages-2-safe-supportive-culture-f-opportunities-for-real-contribution-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/2-safe-supportive-culture/f-opportunities-for-real-contribution.jsx" /* webpackChunkName: "component---src-pages-2-safe-supportive-culture-f-opportunities-for-real-contribution-jsx" */),
  "component---src-pages-2-safe-supportive-culture-quiz-1-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/2-safe-supportive-culture/quiz-1.jsx" /* webpackChunkName: "component---src-pages-2-safe-supportive-culture-quiz-1-jsx" */),
  "component---src-pages-2-safe-supportive-culture-quiz-2-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/2-safe-supportive-culture/quiz-2.jsx" /* webpackChunkName: "component---src-pages-2-safe-supportive-culture-quiz-2-jsx" */),
  "component---src-pages-2-safe-supportive-culture-quiz-3-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/2-safe-supportive-culture/quiz-3.jsx" /* webpackChunkName: "component---src-pages-2-safe-supportive-culture-quiz-3-jsx" */),
  "component---src-pages-2-safe-supportive-culture-quiz-4-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/2-safe-supportive-culture/quiz-4.jsx" /* webpackChunkName: "component---src-pages-2-safe-supportive-culture-quiz-4-jsx" */),
  "component---src-pages-2-safe-supportive-culture-quiz-5-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/2-safe-supportive-culture/quiz-5.jsx" /* webpackChunkName: "component---src-pages-2-safe-supportive-culture-quiz-5-jsx" */),
  "component---src-pages-2-safe-supportive-culture-quiz-6-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/2-safe-supportive-culture/quiz-6.jsx" /* webpackChunkName: "component---src-pages-2-safe-supportive-culture-quiz-6-jsx" */),
  "component---src-pages-3-the-impact-of-stress-on-the-brain-and-body-a-introduction-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/3-the-impact-of-stress-on-the-brain-and-body/a-introduction.jsx" /* webpackChunkName: "component---src-pages-3-the-impact-of-stress-on-the-brain-and-body-a-introduction-jsx" */),
  "component---src-pages-3-the-impact-of-stress-on-the-brain-and-body-b-brain-101-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/3-the-impact-of-stress-on-the-brain-and-body/b-brain-101.jsx" /* webpackChunkName: "component---src-pages-3-the-impact-of-stress-on-the-brain-and-body-b-brain-101-jsx" */),
  "component---src-pages-3-the-impact-of-stress-on-the-brain-and-body-c-the-story-of-stress-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/3-the-impact-of-stress-on-the-brain-and-body/c-the-story-of-stress.jsx" /* webpackChunkName: "component---src-pages-3-the-impact-of-stress-on-the-brain-and-body-c-the-story-of-stress-jsx" */),
  "component---src-pages-3-the-impact-of-stress-on-the-brain-and-body-d-behavior-tells-a-story-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/3-the-impact-of-stress-on-the-brain-and-body/d-behavior-tells-a-story.jsx" /* webpackChunkName: "component---src-pages-3-the-impact-of-stress-on-the-brain-and-body-d-behavior-tells-a-story-jsx" */),
  "component---src-pages-3-the-impact-of-stress-on-the-brain-and-body-e-growth-mindset-and-rewiring-the-brain-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/3-the-impact-of-stress-on-the-brain-and-body/e-growth-mindset-and-rewiring-the-brain.jsx" /* webpackChunkName: "component---src-pages-3-the-impact-of-stress-on-the-brain-and-body-e-growth-mindset-and-rewiring-the-brain-jsx" */),
  "component---src-pages-3-the-impact-of-stress-on-the-brain-and-body-quiz-1-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/3-the-impact-of-stress-on-the-brain-and-body/quiz-1.jsx" /* webpackChunkName: "component---src-pages-3-the-impact-of-stress-on-the-brain-and-body-quiz-1-jsx" */),
  "component---src-pages-3-the-impact-of-stress-on-the-brain-and-body-quiz-2-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/3-the-impact-of-stress-on-the-brain-and-body/quiz-2.jsx" /* webpackChunkName: "component---src-pages-3-the-impact-of-stress-on-the-brain-and-body-quiz-2-jsx" */),
  "component---src-pages-3-the-impact-of-stress-on-the-brain-and-body-quiz-3-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/3-the-impact-of-stress-on-the-brain-and-body/quiz-3.jsx" /* webpackChunkName: "component---src-pages-3-the-impact-of-stress-on-the-brain-and-body-quiz-3-jsx" */),
  "component---src-pages-3-the-impact-of-stress-on-the-brain-and-body-quiz-4-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/3-the-impact-of-stress-on-the-brain-and-body/quiz-4.jsx" /* webpackChunkName: "component---src-pages-3-the-impact-of-stress-on-the-brain-and-body-quiz-4-jsx" */),
  "component---src-pages-3-the-impact-of-stress-on-the-brain-and-body-quiz-5-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/3-the-impact-of-stress-on-the-brain-and-body/quiz-5.jsx" /* webpackChunkName: "component---src-pages-3-the-impact-of-stress-on-the-brain-and-body-quiz-5-jsx" */),
  "component---src-pages-3-the-impact-of-stress-on-the-brain-and-body-quiz-6-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/3-the-impact-of-stress-on-the-brain-and-body/quiz-6.jsx" /* webpackChunkName: "component---src-pages-3-the-impact-of-stress-on-the-brain-and-body-quiz-6-jsx" */),
  "component---src-pages-4-everything-is-a-skill-a-introduction-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/4-everything-is-a-skill/a-introduction.jsx" /* webpackChunkName: "component---src-pages-4-everything-is-a-skill-a-introduction-jsx" */),
  "component---src-pages-4-everything-is-a-skill-b-teaching-life-skills-through-sports-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/4-everything-is-a-skill/b-teaching-life-skills-through-sports.jsx" /* webpackChunkName: "component---src-pages-4-everything-is-a-skill-b-teaching-life-skills-through-sports-jsx" */),
  "component---src-pages-4-everything-is-a-skill-c-advanced-coaching-skills-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/4-everything-is-a-skill/c-advanced-coaching-skills.jsx" /* webpackChunkName: "component---src-pages-4-everything-is-a-skill-c-advanced-coaching-skills-jsx" */),
  "component---src-pages-4-everything-is-a-skill-d-planning-for-success-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/4-everything-is-a-skill/d-planning-for-success.jsx" /* webpackChunkName: "component---src-pages-4-everything-is-a-skill-d-planning-for-success-jsx" */),
  "component---src-pages-4-everything-is-a-skill-quiz-1-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/4-everything-is-a-skill/quiz-1.jsx" /* webpackChunkName: "component---src-pages-4-everything-is-a-skill-quiz-1-jsx" */),
  "component---src-pages-4-everything-is-a-skill-quiz-2-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/4-everything-is-a-skill/quiz-2.jsx" /* webpackChunkName: "component---src-pages-4-everything-is-a-skill-quiz-2-jsx" */),
  "component---src-pages-4-everything-is-a-skill-quiz-3-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/4-everything-is-a-skill/quiz-3.jsx" /* webpackChunkName: "component---src-pages-4-everything-is-a-skill-quiz-3-jsx" */),
  "component---src-pages-4-everything-is-a-skill-quiz-4-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/4-everything-is-a-skill/quiz-4.jsx" /* webpackChunkName: "component---src-pages-4-everything-is-a-skill-quiz-4-jsx" */),
  "component---src-pages-4-everything-is-a-skill-quiz-5-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/4-everything-is-a-skill/quiz-5.jsx" /* webpackChunkName: "component---src-pages-4-everything-is-a-skill-quiz-5-jsx" */),
  "component---src-pages-4-everything-is-a-skill-quiz-6-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/4-everything-is-a-skill/quiz-6.jsx" /* webpackChunkName: "component---src-pages-4-everything-is-a-skill-quiz-6-jsx" */),
  "component---src-pages-404-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-5-fit-for-life-a-introduction-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/5-fit-for-life/a-introduction.jsx" /* webpackChunkName: "component---src-pages-5-fit-for-life-a-introduction-jsx" */),
  "component---src-pages-5-fit-for-life-b-fit-for-life-today-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/5-fit-for-life/b-fit-for-life-today.jsx" /* webpackChunkName: "component---src-pages-5-fit-for-life-b-fit-for-life-today-jsx" */),
  "component---src-pages-5-fit-for-life-c-mental-health-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/5-fit-for-life/c-mental-health.jsx" /* webpackChunkName: "component---src-pages-5-fit-for-life-c-mental-health-jsx" */),
  "component---src-pages-5-fit-for-life-d-social-well-being-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/5-fit-for-life/d-social-well-being.jsx" /* webpackChunkName: "component---src-pages-5-fit-for-life-d-social-well-being-jsx" */),
  "component---src-pages-5-fit-for-life-e-keep-girls-healthy-and-in-the-game-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/5-fit-for-life/e-keep-girls-healthy-and-in-the-game.jsx" /* webpackChunkName: "component---src-pages-5-fit-for-life-e-keep-girls-healthy-and-in-the-game-jsx" */),
  "component---src-pages-5-fit-for-life-f-in-the-future-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/5-fit-for-life/f-in-the-future.jsx" /* webpackChunkName: "component---src-pages-5-fit-for-life-f-in-the-future-jsx" */),
  "component---src-pages-5-fit-for-life-quiz-1-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/5-fit-for-life/quiz-1.jsx" /* webpackChunkName: "component---src-pages-5-fit-for-life-quiz-1-jsx" */),
  "component---src-pages-5-fit-for-life-quiz-2-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/5-fit-for-life/quiz-2.jsx" /* webpackChunkName: "component---src-pages-5-fit-for-life-quiz-2-jsx" */),
  "component---src-pages-5-fit-for-life-quiz-3-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/5-fit-for-life/quiz-3.jsx" /* webpackChunkName: "component---src-pages-5-fit-for-life-quiz-3-jsx" */),
  "component---src-pages-5-fit-for-life-quiz-4-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/5-fit-for-life/quiz-4.jsx" /* webpackChunkName: "component---src-pages-5-fit-for-life-quiz-4-jsx" */),
  "component---src-pages-5-fit-for-life-quiz-5-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/5-fit-for-life/quiz-5.jsx" /* webpackChunkName: "component---src-pages-5-fit-for-life-quiz-5-jsx" */),
  "component---src-pages-5-fit-for-life-quiz-6-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/5-fit-for-life/quiz-6.jsx" /* webpackChunkName: "component---src-pages-5-fit-for-life-quiz-6-jsx" */),
  "component---src-pages-about-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-conclusion-sports-and-society-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/conclusion-sports-and-society.jsx" /* webpackChunkName: "component---src-pages-conclusion-sports-and-society-jsx" */),
  "component---src-pages-index-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-introduction-why-it-matters-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/introduction-why-it-matters.jsx" /* webpackChunkName: "component---src-pages-introduction-why-it-matters-jsx" */),
  "component---src-pages-resources-jsx": () => import("/home/circleci/attn-labs/adidas-up2us/src/pages/resources.jsx" /* webpackChunkName: "component---src-pages-resources-jsx" */)
}

